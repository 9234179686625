import type { ConstantLengthArray } from '~/utils/types';
import type { CompositeAggKeys, ElasticSearchCategories } from '../type/api';
import type { SearchListKeys } from '../type/filter-state';

interface SearchCategoryConfig {
    children: string;
    mobileName?: string;
    category?: string;
    index?: ElasticSearchCategories;
    noResults: string;
    categoryLink?: string;
    itemType: string;
}

export const SEARCH_CATEGORIES: ConstantLengthArray<SearchCategoryConfig, 5> = [
    {
        children: 'All',
        noResults: 'results',
        categoryLink: undefined,
        itemType: 'Result',
    },
    {
        children: 'Products',
        category: 'products',
        index: 'product',
        noResults: 'products',
        categoryLink: '/products',
        itemType: 'Product',
    },
    {
        children: 'Projects',
        category: 'projects',
        index: 'project',
        noResults: 'projects',
        categoryLink: '/projects',
        itemType: 'Project',
    },
    {
        children: 'Professionals',
        mobileName: 'Pros',
        category: 'professionals',
        index: 'professional',
        noResults: 'professionals',
        categoryLink: '/professionals',
        itemType: 'Professional',
    },
    {
        children: 'Articles',
        category: 'articles',
        index: 'blogpost',
        noResults: 'results',
        categoryLink: '/articles',
        itemType: 'Article',
    },
];

export const SEARCH_LIST_AGG_PARAM_KEY_MAP: Record<
    SearchListKeys,
    CompositeAggKeys
> = {
    professionals: 'aggProfessionals',
    materials: 'aggProductMaterials',
    professions: 'aggProfessions',
    libraryRanges: 'aggLibraryRanges',
    associations: 'aggAssociations',
    designers: 'aggDesigners',
    brands: 'aggBrands',
    professionalCategories: 'aggProfessionalCategories',
    downloads: 'aggDownloads',
};

export const SEARCH_QUERY_KEY = 'search';

export const LOCATION_SEARCH_PARAM_KEYS = {
    suburb: 'suburb',
    district: 'district',
    city: 'city',
    region: 'region',
    state: 'location-state',
    country: 'country',
};
