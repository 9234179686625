import type { FilterSection } from '../components/filter-drawer/FilterDrawer';
import type { FilterType } from '../components/filter-drawer/FilterFactory';
import type { FacetOptionFilterState } from '../type/filter-state';

/**
 * Create facet filter section based on aggregation and filter state
 * @param facets
 * @returns
 */
export const createFacetFilterSections = (
    facets: FacetOptionFilterState[]
): FilterSection[] => {
    // sort by isGeneral and ID
    return facets.map((facet) => {
        const filter: FilterType = {
            kind: 'SearchList',
            filterKey: 'facetOptions',
            subFilterKey: facet.id,
            props: {
                items: [],
                children: () => <div />,
                hideSearch: !facet.showSearch,
            },
        };
        return {
            title: facet.name,
            key: `facet_${facet.name}`,
            filter,
        };
    });
};
