import type {
    FilterSection,
    FilterSectionFilterType,
} from '../components/filter-drawer/FilterDrawer';
import { getValueFromShorthandValue } from './get-value-from-shorthand-value';
import type {
    FilterStates,
    FilterBarCheckboxFilter,
} from '../type/filter-state';

import type { CheckboxFilterState } from '../components/filter-drawer/FilterCheckbox';
import type { SearchListFilterState } from '../components/filter-drawer/FilterSearchList';

const getDisplayCheckboxFilter = (
    filter: FilterSectionFilterType,
    filterStates: FilterStates,
    isMobile = false
): FilterBarCheckboxFilter[] => {
    if (filter.kind === 'FilterGroup') {
        return filter.filters.reduce(
            (filters, childFilter) =>
                filters.concat(
                    getDisplayCheckboxFilter(
                        childFilter,
                        filterStates,
                        isMobile
                    )
                ),
            [] as FilterBarCheckboxFilter[]
        );
    }
    switch (filter.kind) {
        case 'Checkbox':
            return getDisplayCheckboxFilterForCheckbox(
                filter,
                filterStates,
                isMobile
            );
        case 'SearchList':
            return getDisplayCheckboxFilterForSearchList(
                filter,
                filterStates,
                isMobile
            );
        default:
            return [];
    }
};

const getDisplayCheckboxFilterForCheckbox = (
    filter: FilterSectionFilterType,
    filterStates: FilterStates,
    isMobile = false
): FilterBarCheckboxFilter[] => {
    if (
        filter.kind !== 'Checkbox' ||
        (isMobile
            ? !filter.displayOnMobileFilterBar
            : !filter.displayOnFilterBar) ||
        !filterStates[filter.filterKey] ||
        (filterStates[filter.filterKey] as CheckboxFilterState).disabled
    )
        return [];

    const { filterKey, props } = filter;

    const label = getValueFromShorthandValue(props.label, filterKey);
    const state = filterStates[filterKey] as CheckboxFilterState;
    const disabled = !!state?.disabled;
    const checked = !!state?.checked;
    return [
        {
            filterKey,
            label,
            checked,
            disabled,
            props,
            order: filter.filterBarOrder || 0,
            newState: {
                kind: 'Checkbox',
                checked: !checked,
            },
        },
    ];
};

const getDisplayCheckboxFilterForSearchList = (
    filter: FilterSectionFilterType,
    filterStates: FilterStates,
    isMobile = false
): FilterBarCheckboxFilter[] => {
    if (
        filter.kind !== 'SearchList' ||
        (isMobile
            ? !filter.displayOnMobileFilterBar
            : !filter.displayOnFilterBar) ||
        !filterStates[filter.filterKey]
    )
        return [];
    const state = filterStates[filter.filterKey] as SearchListFilterState;

    const checked = !!state.selectedItems?.length;

    const { filterKey } = filter;

    const label = filter.filterBarTitle ?? '';
    return [
        {
            filterKey,
            label,
            checked,
            disabled: false,
            order: filter.filterBarOrder || 0,
            props: {},
            newState: {
                kind: 'SearchList',
                selectedItems: checked
                    ? []
                    : state.items.map((item) => {
                          item.checked = true;
                          return item;
                      }),
            },
        },
    ];
};

export const getFilterBarFilters = (
    filterSections: FilterSection[],
    filterStates: FilterStates,
    isMobile = false
): FilterBarCheckboxFilter[] => {
    return filterSections
        .reduce(
            (filters, { filter }) =>
                filters.concat(
                    getDisplayCheckboxFilter(filter, filterStates, isMobile)
                ),
            [] as FilterBarCheckboxFilter[]
        )
        .sort((a, b) => a.order - b.order);
};
