import type { RangeFilterKeys } from '../type/api';

export const RangeDivisors: Record<RangeFilterKeys, number> = {
    priceRange: 100,
    lengthRange: 1000,
    widthRange: 1000,
    heightRange: 1000,
    diameterRange: 1000,
    weightRange: 1000,
};
