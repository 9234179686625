import { useFetcher, useSearchParams } from '@remix-run/react';
import type {
    ElasticSearchAggregationParams,
    ElasticSearchCategories,
} from '../type/api';
import type { FilterStates, SearchListKeys } from '../type/filter-state';
import { applyESSearchSort } from '../util/apply-es-search-sort';
import { createAggregationParams } from '../util/create-es-aggregation-params';
import { ES_AGGREGATE_API } from './use-filter-states';
import type { loader } from '~/routes/remix-api.search.aggregate';
import { useEffect, useCallback, useRef } from 'react';
import { useLatest } from 'ahooks';
import { SEARCH_CATEGORIES } from '@modules/search/config';
import { parseURL } from '@archipro-website/top-navigation';

type AggregateType =
    | 'filterAggregation'
    | 'searchListLoadMore'
    | 'searchListSearch';

export type AggregateState = 'wait' | 'loading' | 'loaded' | 'cancel';

export interface AggregateRequest {
    aggregateType: AggregateType;
    // set this when aggregateType is searchListLoadMore or searchListSearch
    aggregateFilter?: SearchListKeys;
    filterStates: FilterStates;
    aggregationsParams?: ElasticSearchAggregationParams;
    onAggregateStateChange: (
        request: AggregateRequest,
        state: AggregateState,
        data?: FilterStates
    ) => void;
}

interface UseAggregationOptions {
    category?: ElasticSearchCategories;
}

/**
 * This hook is used to fetch the ES aggregation result based on filter states and params
 */
const useAggregation = ({ category }: UseAggregationOptions) => {
    const fetcher = useFetcher<typeof loader>();
    const aggregateFetcher = useLatest(fetcher);
    const [searchParams] = useSearchParams();
    const requests = useRef<AggregateRequest[]>([]);
    const currentRequest = useRef<AggregateRequest | null>(null);

    const executeAggregate = useCallback(() => {
        const allRequests = requests.current;
        if (
            aggregateFetcher.current.state !== 'idle' ||
            !allRequests.length ||
            currentRequest.current
        )
            return;
        const request = allRequests.shift();

        if (!request) {
            executeAggregate();
            return;
        }
        currentRequest.current = request;
        const search = searchParams.get('search') ?? '';
        const queryParams = createAggregationParams(
            request.filterStates,
            search,
            category,
            request.aggregationsParams
        );
        if (!queryParams) {
            currentRequest.current = null;
            request.onAggregateStateChange(request, 'cancel');
            executeAggregate();
            return;
        }

        applyESSearchSort(location.pathname, queryParams.filters);
        const { basePath } = parseURL(location.pathname);
        const isCategoryPage = !!SEARCH_CATEGORIES.find(
            (cfg) => cfg.categoryLink && basePath.startsWith(cfg.categoryLink)
        );
        if (isCategoryPage && !queryParams.filters.categoryLink) {
            queryParams.filters.categoryLink = basePath;
        }

        request.onAggregateStateChange(request, 'loading');
        aggregateFetcher.current.load(
            `${ES_AGGREGATE_API}?queryParams=${encodeURIComponent(
                JSON.stringify(queryParams)
            )}`
        );
    }, [category, aggregateFetcher, searchParams]);

    const aggregate = useCallback(
        (request: AggregateRequest) => {
            const allRequests = requests.current;
            const duplicateIndex = allRequests.findIndex(
                (queueRequest) =>
                    queueRequest.aggregateType === request.aggregateType &&
                    queueRequest.aggregateFilter === request.aggregateFilter
            );
            if (duplicateIndex !== -1) {
                const duplicateRequest = allRequests[duplicateIndex]!;
                duplicateRequest.onAggregateStateChange(
                    duplicateRequest,
                    'cancel'
                );
                allRequests.splice(duplicateIndex, 1);
            }
            allRequests.push(request);
            request.onAggregateStateChange(request, 'wait');
            executeAggregate();
        },
        [executeAggregate]
    );

    useEffect(() => {
        if (
            aggregateFetcher.current.state !== 'idle' ||
            !currentRequest.current
        )
            return;
        const request = currentRequest.current;
        const data = aggregateFetcher.current.data;
        request.onAggregateStateChange(request, 'loaded', data ?? undefined);
        currentRequest.current = null;
        if (requests.current.length) {
            executeAggregate();
        }
    }, [
        aggregateFetcher,
        aggregateFetcher.current.state,
        aggregateFetcher.current.data,
        currentRequest,
        executeAggregate,
    ]);

    return { aggregate, loading: fetcher.state === 'loading' };
};

export { useAggregation };
