import type { CheckboxGroupFilterState } from '@modules/search/components/filter-drawer/FilterCheckboxGroup';
import type { RangeSelectorFilterState } from '@modules/search/components/filter-drawer/FilterRangeSelector';
import type { SearchListFilterState } from '@modules/search/components/filter-drawer/FilterSearchList';
import type { CheckboxFilterState } from '../components/filter-drawer/FilterCheckbox';
import type { RadioGroupFilterState } from '../components/filter-drawer/FilterRadioGroup';
import { RangeDivisors } from '../config/range-divisor';
import type {
    BusinessFocusType,
    CheckboxFilterKeys,
    CheckboxGroupFilterKeys,
    ElasticSearchAggregateProps,
    ElasticSearchAggregationParams,
    ElasticSearchCategories,
    ElasticSearchQueryFilters,
    ElasticSearchViewProjectsByOptions,
    FacetOptionsAggregationParams,
    FacetOptionsFilterParams,
    RangeFilterKeys,
    SearchListFilterKeys,
} from '../type/api';
import type { FilterStates } from '../type/filter-state';
import type {AwardFilterState} from "@modules/search/components/awards-filter-tree/AwardsFilterTree";

export const DEFAULT_SEARCH_LIST_SIZE = 100;

const applyCheckboxFilter = (
    filters: ElasticSearchQueryFilters,
    filterKey: CheckboxFilterKeys,
    state?: CheckboxFilterState
) => {
    if (!state?.checked) return;
    filters[filterKey] = true;
};

const applyCheckboxGroupFilter = (
    filters: ElasticSearchQueryFilters,
    filterKey: CheckboxGroupFilterKeys,
    state?: CheckboxGroupFilterState
) => {
    if (!state?.checkedItems?.length) return;
    const items = state.checkedItems.reduce((values, item) => {
        if (item.value) values.push(item.value);
        return values;
    }, [] as string[]);
    if (!items.length) return;
    filters[filterKey] = items;
};

const applyRangeFilter = (
    filters: ElasticSearchQueryFilters,
    filterKey: RangeFilterKeys,
    state?: RangeSelectorFilterState
) => {
    if (!state?.value) return;
    const { min, max } = state.value;
    const multiplier = RangeDivisors[filterKey];
    filters[filterKey] = [min * multiplier, max * multiplier];
};

const applySearchListFilter = (
    filters: ElasticSearchQueryFilters,
    filterKey: SearchListFilterKeys,
    state?: SearchListFilterState
) => {
    if (!state?.selectedItems?.length) return;
    const checkedItems = state.selectedItems.reduce((values, item) => {
        values.push(item.value);
        return values;
    }, [] as string[]);
    if (!checkedItems.length) return;
    filters[filterKey] = checkedItems;
};

const applyProjectCountFilter = (
    filters: ElasticSearchQueryFilters,
    state?: RadioGroupFilterState
) => {
    if (!state?.selectedItem || !state.selectedItem.value) return;
    const value = Number(state.selectedItem.value);
    if (!value) return;
    filters.projectCount = value;
};

const applyBusinessFocusesFilter = (
    filters: ElasticSearchQueryFilters,
    state?: RadioGroupFilterState
) => {
    if (!state?.selectedItem || !state.selectedItem.value) return;
    const value = state.selectedItem.value;
    if (!value) return;
    filters.businessFocuses = value.split(',') as BusinessFocusType[];
};

const applyViewProjectsByFilter = (
    filters: ElasticSearchQueryFilters,
    state?: RadioGroupFilterState
) => {
    if (!state?.selectedItem || !state.selectedItem.value) return;
    const value = state.selectedItem.value;
    if (!value) return;
    filters.viewProjectsBy = value as ElasticSearchViewProjectsByOptions;
};

const applyAwardsFilter = (
    filters: ElasticSearchQueryFilters,
    filterKey: CheckboxGroupFilterKeys,
    state?: AwardFilterState
) => {
    if (!state?.checkedItems?.length) return;
    const items = state.checkedItems.reduce((values, item) => {
        if (item.value) values.push(item.value);
        return values;
    }, [] as string[]);
    if (!items.length) return;
    filters[filterKey] = items;
};

export const createAggregationParams = (
    filterStates: FilterStates,
    search?: string,
    searchType?: ElasticSearchCategories,
    requestAggregations?: ElasticSearchAggregationParams
): ElasticSearchAggregateProps | null => {
    const filters: ElasticSearchQueryFilters = {
        categoryLink: filterStates.category?.selectedCategory?.id,
    };

    applyCheckboxFilter(filters, 'isPurchasable', filterStates.isPurchasable);
    applyCheckboxFilter(filters, 'isOnSale', filterStates.isOnSale);
    applyCheckboxFilter(filters, 'isInStock', filterStates.isInStock);
    applyCheckboxFilter(
        filters,
        'isDeliveryAvailable',
        filterStates.isDeliveryAvailable
    );
    applyCheckboxFilter(
        filters,
        'isSampleAvailable',
        filterStates.isSampleAvailable
    );
    applyCheckboxFilter(filters, 'invalidLink', filterStates.invalidLink);
    applyCheckboxFilter(filters, 'hasProducts', filterStates.hasProducts);
    applyCheckboxFilter(filters, 'hasVideos', filterStates.hasVideos);
    applyRangeFilter(filters, 'priceRange', filterStates.priceRange);
    applyRangeFilter(filters, 'lengthRange', filterStates.lengthRange);
    applyRangeFilter(filters, 'widthRange', filterStates.widthRange);
    applyRangeFilter(filters, 'heightRange', filterStates.heightRange);
    applyRangeFilter(filters, 'diameterRange', filterStates.diameterRange);
    applyRangeFilter(filters, 'weightRange', filterStates.weightRange);
    applySearchListFilter(filters, 'materials', filterStates.materials);
    applySearchListFilter(filters, 'brands', filterStates.brands);
    applySearchListFilter(filters, 'designers', filterStates.designers);
    applySearchListFilter(filters, 'professionals', filterStates.professionals);
    applySearchListFilter(filters, 'professions', filterStates.professions);
    applySearchListFilter(filters, 'downloads', filterStates.downloads);
    applyCheckboxGroupFilter(filters, 'colors', filterStates.colors);
    applyProjectCountFilter(filters, filterStates.projectCount);
    applyBusinessFocusesFilter(filters, filterStates.businessFocuses);
    applyCheckboxGroupFilter(
        filters,
        'professionalParents',
        filterStates.professionalParents
    );
    applySearchListFilter(filters, 'libraryRanges', filterStates.libraryRanges);
    applyViewProjectsByFilter(filters, filterStates.viewProjectsBy);
    applySearchListFilter(filters, 'associations', filterStates.associations);
    filters.professionalsUsed = filterStates.professionalsUsed;
    filters.visibilities = filterStates.visibilities;
    filters.searchLocation = filterStates.searchLocation?.selectedLocation;
    applySearchListFilter(
        filters,
        'professionalCategories',
        filterStates.professionalCategories
    );
    applyAwardsFilter(filters, 'awards', filterStates.awards);

    const facetOptions: FacetOptionsFilterParams[] = [];
    const aggFacetOptions: FacetOptionsAggregationParams[] = [];
    filterStates.facetOptions?.forEach((facetOptionState) => {
        if (facetOptionState.state.search) {
            aggFacetOptions.push({
                facet: facetOptionState.id,
                search: facetOptionState.state.search,
            });
        }
        facetOptionState.state.selectedItems?.forEach((selectedItem) => {
            facetOptions.push({
                facet: facetOptionState.id,
                option: selectedItem.value,
            });
        });
    });
    filters.facetOptions = facetOptions;

    let aggregations: ElasticSearchAggregationParams | undefined;
    if (requestAggregations) {
        aggregations = requestAggregations;
    } else {
        if (searchType === 'product') {
            aggregations = {
                aggCategories: {},
                aggProductPriceRange: true,
                aggProductItemsCount: true,
                aggProductColors: true,
                aggProductMaterials: {
                    search: filterStates.materials?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.materials?.items.length ?? 0
                    ),
                },
                aggProductLengthRange: true,
                aggProductWidthRange: true,
                aggProductHeightRange: true,
                aggProductDiameterRange: true,
                aggProductWeightRange: true,
                aggLibraryRanges: {
                    search: filterStates.libraryRanges?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.libraryRanges?.items.length ?? 0
                    ),
                },
                aggProfessionals: {
                    search: filterStates.professionals?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.professionals?.items.length ?? 0
                    ),
                },
                aggBrands: {
                    search: filterStates.brands?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.brands?.items.length ?? 0
                    ),
                },
                aggDesigners: {
                    search: filterStates.designers?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.designers?.items.length ?? 0
                    ),
                },
                aggFacetOptions,
                aggDownloads: {},
                aggAwards: true,
            };
        } else if (searchType === 'project') {
            aggregations = {
                aggCategories: {},
                aggProfessionals: {
                    search: filterStates.professionals?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.professionals?.items.length ?? 0
                    ),
                },
                aggProjectItemsCount: true,
                aggProfessions: {
                    search: filterStates.professions?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.professions?.items.length ?? 0
                    ),
                },
                aggProfessionalCategories: {
                    search:
                        filterStates.professionalCategories?.search ??
                        undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.professionalCategories?.items.length ?? 0
                    ),
                },
                aggAwards: true,
            };
        } else if (searchType === 'professional') {
            aggregations = {
                aggCategories: {},
                aggProfessionalProjectCountRanges: true,
                aggBusinessFocuses: true,
                aggProfessionalItemsCount: true,
                aggAssociations: {
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.associations?.items.length ?? 0
                    ),
                },
            };
        } else if (searchType === 'blogpost') {
            aggregations = {
                aggCategories: {},
                aggProfessionals: {
                    search: filterStates.professionals?.search ?? undefined,
                    size: Math.max(
                        DEFAULT_SEARCH_LIST_SIZE,
                        filterStates.professionals?.items.length ?? 0
                    ),
                },
            };
        }
    }

    return { search, searchType, filters, aggregations };
};
