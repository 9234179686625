import type { FilterSection } from '@modules/search/components/filter-drawer/FilterDrawer';

export const ARTICLE_FILTERS: FilterSection[] = [
    {
        title: 'Professionals',
        filter: {
            kind: 'SearchList',
            filterKey: 'professionals',
            props: { items: [], children: () => <div /> },
        },
    },
];
