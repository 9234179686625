import type { Location } from '@remix-run/react';
import type { Tracker } from '@archipro-website/tracker';
import type { FilterState } from '~/modules/search/type/filter-state';
import { parseURL } from '@archipro-website/top-navigation';
import type { RangeSelectorFilterState } from '~/modules/search/components/filter-drawer/FilterRangeSelector';
import type { CheckboxGroupFilterState } from '~/modules/search/components/filter-drawer/FilterCheckboxGroup';
import type { SearchListFilterState } from '~/modules/search/components/filter-drawer/FilterSearchList';
import type { LocationFilterState } from '~/modules/search/components/filter-drawer/FilterLocation';

const PATH_WHITELIST = [
    '/products',
    '/projects',
    '/professionals',
    '/professional/',
];

export const trackAppliedFilter = (
    tracker: Tracker,
    location: Location,
    newFilterState: Partial<FilterState>,
    prevFilterState: FilterState
) => {
    const { basePath } = parseURL(location.pathname);

    let isTargetPage = false;
    for (const path of PATH_WHITELIST) {
        if (basePath.startsWith(path)) {
            isTargetPage = true;
            break;
        }
    }

    if (!isTargetPage) {
        return;
    }

    // fire the event only if we applied a filter.
    // would not fire the event for unselecting a filter
    if (!isFilterApplied(newFilterState, prevFilterState)) {
        return;
    }

    tracker.log('FilterApplied', {
        url: new URL(window.location.href),
        targetTracker: ['archiproTrackerV2', 'ga4Tracker'],
    });
};

const isFilterApplied = (
    newFilterState: Partial<FilterState>,
    prevFilterState: FilterState
): boolean => {
    if (newFilterState.kind !== prevFilterState.kind) {
        // should not happen. just a runtime protection for the `as` bellow.
        return false;
    }
    switch (newFilterState.kind) {
        case 'Checkbox':
            return !!newFilterState.checked;
        case 'CheckboxGroup':
            const prevCBGState = prevFilterState as CheckboxGroupFilterState;
            return (
                (newFilterState.checkedItems?.length ?? 0) >
                (prevCBGState.checkedItems?.length ?? 0)
            );
        case 'RangeSelector':
            const prevRSState = prevFilterState as RangeSelectorFilterState;
            return (
                newFilterState.value?.min !== prevRSState.range?.min ||
                newFilterState.value?.max !== prevRSState.range?.max
            );
        case 'SearchList':
            const prevSLState = prevFilterState as SearchListFilterState;
            return (
                (newFilterState.selectedItems?.length ?? 0) >
                (prevSLState.selectedItems?.length ?? 0)
            );
        case 'RadioGroup':
            return (
                !!newFilterState.selectedItem &&
                !newFilterState.selectedItem?.isDefault
            );
        case 'Location':
            const prevLState = prevFilterState as LocationFilterState;
            return (
                !!newFilterState.selectedLocation &&
                (newFilterState.selectedLocation.suburb !==
                    prevLState.selectedLocation?.suburb ||
                    newFilterState.selectedLocation.city !==
                        prevLState.selectedLocation?.city ||
                    newFilterState.selectedLocation?.region !==
                        prevLState.selectedLocation?.region)
            );
        default:
            return false;
    }
};
