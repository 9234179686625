import type { FilterSection } from '@modules/search/components/filter-drawer/FilterDrawer';

export const PROFESSIONAL_FILTERS: FilterSection[] = [
    {
        title: 'Staff admin filters',
        activePanel: true,
        adminOnly: true,
        filter: {
            kind: 'FilterGroup',
            key: 'admin filters',
            gap: 'space-8',
            filters: [
                {
                    kind: 'Checkbox',
                    filterKey: 'invalidLink',
                    adminOnly: true,
                    props: { label: 'Invalid link' },
                },
            ],
        },
    },
    {
        title: 'Locations',
        filter: {
            kind: 'Location',
            filterKey: 'searchLocation',
        },
        activePanel: true,
    },
    {
        title: 'Business focus',
        filter: {
            kind: 'RadioGroup',
            filterKey: 'businessFocuses',
            props: { items: [] },
        },
        activePanel: true,
    },
    {
        title: 'Associations',
        filter: {
            kind: 'SearchList',
            filterKey: 'associations',
            props: {
                items: [],
                children: () => <div />,
                hideSearch: true,
            },
        },
    },
    {
        title: 'Projects listed',
        filter: {
            kind: 'RadioGroup',
            filterKey: 'projectCount',
            props: { items: [] },
        },
    },
    {
        title: 'Interactive',
        filter: {
            kind: 'FilterGroup',
            key: 'interactiveGroup',
            gap: 'space-8',
            filters: [
                {
                    kind: 'Checkbox',
                    filterKey: 'hasAwards',
                    hideInFilters: true,
                    props: { label: 'Award winning Professionals' },
                },
            ],
        },
    },
];
