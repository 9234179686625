import type { Options } from 'ahooks/lib/useRequest/src/types';
import type { Range } from '@archipro-design/aria';
import type { SupportedCountry } from 'generated/graphql';

export type ElasticSearchEndpoints =
    | 'searchnav'
    | 'search'
    | 'category'
    | 'aggregate'
    | 'searchDetail';

export type ElasticSearchSortOptions =
    | 'alphabetical'
    | 'updated'
    | 'created'
    | 'popular'
    | 'featured'
    | 'new'
    | 'trending'
    | 'lowPrice'
    | 'highPrice'
    | 'uncategorised'
    | 'newlyCreated'
    | 'invalidLink'
    | 'featured'
    | 'draft'
    | null;

export const SUPPORTED_SORT_OPTIONS: ElasticSearchSortOptions[] = [
    'alphabetical',
    'updated',
    'created',
    'popular',
    'featured',
    'new',
    'trending',
    'lowPrice',
    'highPrice',
    'uncategorised',
    'newlyCreated',
    'invalidLink',
    'featured',
];

export type ElasticSearchViewProjectsByOptions =
    | 'all'
    | 'our-projects'
    | 'projects-we-tagged-in';

export type ElasticSearchCategories =
    | 'professional'
    | 'project'
    | 'product'
    | 'blogpost';

export type ElasticSearchVisibility =
    | 'InLibrary'
    | 'ProfileOnly'
    | 'PendingApproval'
    | 'Disabled'
    | 'Draft'
    | 'NotLive'
    | 'InSearch'
    | 'Scheduled';

export type ElasticSearchResultType = 'hits' | 'leftNav';

export interface UseElasticSearchProps<TData> {
    query: ElasticSearchQueryProps;
    endpoint: ElasticSearchEndpoints;
    fetchOptions?: Options<TData, []>;
}

export interface ElasticSearchQueryProps {
    search?: string;
    searchEmbedding?: number[];
    vectorKValue?: number;
    sourceFilter?: string[];
    categoryType?: string;
    searchType?: ElasticSearchCategories;
    filters?: ElasticSearchQueryFilters;
    page?: number;
    sortBy?: ElasticSearchSortOptions;
    size?: number;
    explainScore?: boolean;
    resultType?: ElasticSearchResultType;
    indexVersion?: number;
    aggregations?: ElasticSearchAggregations;
}

type ElasticSearchOrder = 'asc' | 'desc';

export interface ElasticSearchCompositeAggParams {
    size?: number;
    search?: string;
    order?: ElasticSearchOrder;
    after?: Record<string, unknown>;
}

export interface ElasticSearchAggregateProps {
    search?: string;
    searchEmbedding?: number[];
    vectorKValue?: number;
    searchType?: ElasticSearchCategories;
    filters: ElasticSearchQueryFilters;
    aggregations?: ElasticSearchAggregationParams;
    indexVersion?: number;
}

export interface FacetOptionsAggregationParams {
    facet: string;
    search?: string;
}

export interface ElasticSearchAggregationParams {
    aggCategories?: ElasticSearchCompositeAggParams;
    aggProfessionals?: ElasticSearchCompositeAggParams;
    aggProfessionalCategories?: ElasticSearchCompositeAggParams;
    aggRegions?: ElasticSearchCompositeAggParams;
    aggProductPriceRange?: boolean;
    aggProductItemsCount?: boolean;
    aggProductColors?: boolean;
    aggProductMaterials?: ElasticSearchCompositeAggParams;
    aggProductLengthRange?: boolean;
    aggProductWidthRange?: boolean;
    aggProductHeightRange?: boolean;
    aggProductDiameterRange?: boolean;
    aggProductWeightRange?: boolean;
    aggBrands?: ElasticSearchCompositeAggParams;
    aggDesigners?: ElasticSearchCompositeAggParams;
    aggProjectItemsCount?: boolean;
    aggProfessions?: ElasticSearchCompositeAggParams;
    aggProfessionalProjectCountRanges?: boolean;
    aggLibraryRanges?: ElasticSearchCompositeAggParams;
    aggBusinessFocuses?: boolean;
    aggAssociations?: ElasticSearchCompositeAggParams;
    aggProfessionalItemsCount?: boolean;
    aggFacetOptions?: FacetOptionsAggregationParams[];
    aggDownloads?: ElasticSearchCompositeAggParams;
    aggAwards?: boolean;
}

export type CompositeAggKeys = Extract<
    keyof ElasticSearchAggregationParams,
    | 'aggProfessionals'
    | 'aggProductMaterials'
    | 'aggProfessions'
    | 'aggRegions'
    | 'aggLibraryRanges'
    | 'aggAssociations'
    | 'aggBrands'
    | 'aggDesigners'
    | 'aggProfessionalCategories'
    | 'aggDownloads'
>;

export interface ElasticSearchColorOption {
    name: string;
    color: string;
}

export type BusinessFocusType =
    | 'Unspecified'
    | 'Mixed'
    | 'Residential'
    | 'Commercial';

export interface BusinessFocusOption {
    type: BusinessFocusType;
    docCount: number;
}

export interface SearchLocation {
    suburb?: string;
    district?: string;
    city?: string;
    region?: string;
    state?: string;
    country?: string;
}

export interface Profession {
    URLSegment: string;
    Title: string;
}

export interface SearchListItem {
    label: string;
    value: string;
}

interface FacetOption {
    id: string;
    name: string;
}

export interface FacetOptionsAggregationResult {
    name: string;
    isGeneral: boolean;
    count: number;
    items: FacetOption[];
    selectedItems?: FacetOption[];
    showSearch?: boolean;
}

export type FacetAggregationResult = Record<
    string,
    FacetOptionsAggregationResult
>;

export interface ElasticSearchAggregations {
    categoryTree?: {
        articles?: SearchCategory;
        products?: SearchCategory;
        professionals?: SearchCategory;
        projects?: SearchCategory;
    };
    productItemsCounts?: {
        purchasableCount?: number;
        onSaleCount?: number;
        inStockCount?: number;
        deliveryAvailableCount?: number;
        sampleAvailableCount?: number;
    };
    totalCount?: number;

    priceRange?: Range;

    productColors?: ElasticSearchColorOption[];
    productMaterials?: {
        after: Record<string, unknown>;
        items: string[];
        selectedItems?: string[];
    };

    lengthRange?: Range;
    widthRange?: Range;
    heightRange?: Range;
    diameterRange?: Range;
    weightRange?: Range;

    professionals?: {
        after: Record<string, unknown>;
        items: SearchProfessional[];
        selectedItems: SearchProfessional[];
    };

    projectItemsCounts?: {
        hasProductItemCount?: number;
        hasVideoItemCount?: number;
        hasAwardItemCount?: number;
        projectOfTheMonthCount?: number;
    };

    professions?: {
        after: Record<string, unknown>;
        items: Profession[];
        selectedItems: Profession[];
    };

    projectCountRanges?: CountRange[];
    libraryRanges?: {
        after: Record<string, unknown>;
        items: SearchListItem[];
        selectedItems: SearchListItem[];
    };
    businessFocuses?: BusinessFocusOption[];
    associations?: {
        after: Record<string, unknown>;
        items: SearchAssociation[];
        selectedItems: SearchAssociation[];
    };

    professionalItemsCounts?: {
        hasAwardItemCount?: number;
    };

    designers?: {
        after: Record<string, unknown>;
        items: SearchListItem[];
        selectedItems: SearchListItem[];
    };

    brands?: {
        after: Record<string, unknown>;
        items: SearchListItem[];
        selectedItems: SearchListItem[];
    };

    facetOptions?: FacetAggregationResult;

    professionalCategories?: {
        after: Record<string, unknown>;
        items: SearchListItem[];
        selectedItems: SearchListItem[];
    };

    downloads?: {
        after: Record<string, unknown>;
        items: SearchListItem[];
        selectedItems: SearchListItem[];
    };
    awards?: {
        after: Record<string, unknown>;
        items: SearchListItem[];
        selectedItems: SearchListItem[];
    };
}

export interface FacetOptionsFilterParams {
    facet: string;
    option: string;
}

export interface ElasticSearchQueryFilters {
    categoryLink?: string;
    country?: SupportedCountry | 'all';

    isPurchasable?: boolean;
    isOnSale?: boolean;
    priceRange?: [number, number];

    isInStock?: boolean;
    isDeliveryAvailable?: boolean;
    isSampleAvailable?: boolean;

    colors?: string[];
    materials?: string[];

    lengthRange?: [number, number];
    widthRange?: [number, number];
    heightRange?: [number, number];
    diameterRange?: [number, number];
    weightRange?: [number, number];

    brands?: string[];
    designers?: string[];

    professionals?: string[];

    hasProducts?: boolean;
    hasVideos?: boolean;
    projectOfTheMonth?: boolean;

    hasAwards?: boolean;

    professions?: string[];
    projectCount?: number;

    profileOnly?: boolean;
    noCategory?: boolean;
    invalidLink?: boolean;

    professionalParents?: string[];
    libraryRanges?: string[];
    businessFocuses?: BusinessFocusType[];
    associations?: string[];
    downloads?: string[];
    awards?: string[];

    viewProjectsBy?: ElasticSearchViewProjectsByOptions;
    // professional project page use only
    professionalsUsed?: number[];

    visibilities?: ElasticSearchVisibility[];
    searchLocation?: SearchLocation;

    facetOptions?: FacetOptionsFilterParams[];

    professionalCategories?: string[];
}

export type FilterKeys = keyof ElasticSearchQueryFilters;

export type CheckboxFilterKeys = Extract<
    FilterKeys,
    | 'isPurchasable'
    | 'isOnSale'
    | 'isInStock'
    | 'isDeliveryAvailable'
    | 'isSampleAvailable'
    | 'hasProducts'
    | 'hasVideos'
    | 'hasAwards'
    | 'projectOfTheMonth'
    | 'invalidLink'
>;

export type CheckboxGroupFilterKeys = Extract<
    FilterKeys,
    'colors' | 'professionalParents' | 'awards'
>;

export type RangeFilterKeys = Extract<
    FilterKeys,
    | 'priceRange'
    | 'lengthRange'
    | 'widthRange'
    | 'heightRange'
    | 'diameterRange'
    | 'weightRange'
>;

export type SearchListFilterKeys = Extract<
    FilterKeys,
    | 'professionals'
    | 'materials'
    | 'libraryRanges'
    | 'associations'
    | 'professions'
    | 'brands'
    | 'designers'
    | 'professionalCategories'
    | 'downloads'
    | 'awards'
>;

export type RadioGroupFilterKeys = Extract<FilterKeys, 'projectCount'>;

export interface UseElasticSearch<T> {
    loading: boolean;
    error: Error | undefined;
    data: T | undefined;
    reset?: () => void;
}

export interface SearchCategory {
    docCount: number;
    title: string;
    url: string;
    child: Record<string, SearchCategory>;
}

export interface SearchProfessional {
    Title: string;
    URLSegment: string;
}

export type SearchAssociation = SearchProfessional;

export interface CountRange {
    from: number;
    count: number;
}

export interface SearchResults<T> {
    hitsPerPage: number;
    nbHits: number;
    nbPages: number;
    page: number;
    maxResultWindow: number;
    processingTimeMS: number;
    query: string;
    searchType: string;
    searchTypeDefault: string;
    expandedQuery?: string;
    hits: T[];
}

export interface ResultItemPricing {
    ShortFormat?: string;
    Amount?: number;
    Currency?: string;
}
