import type { CheckboxOption } from '@archipro-design/aria';
import type {
    FilterSection,
    FilterSectionFilterType,
} from '../components/filter-drawer/FilterDrawer';
import type { FilterSearchListItem } from '../components/filter-drawer/FilterSearchList';
import type {
    AppliedFilterItem,
    FilterState,
    FilterStates,
    FilterStatesKey,
} from '../type/filter-state';
import { getValueFromShorthandValue } from './get-value-from-shorthand-value';
import type { CategoryFilterState } from '@modules/search/components/filter-drawer/FilterCategorySelector';
import { formatSuburbDropdownOptions } from '~/modules/enquiry/util/format-place-dropdown-options';

const getCheckedItemFilter = (
    filterKey: FilterStatesKey,
    checkedItems: CheckboxOption[],
    updateFilter: (
        filterKey: FilterStatesKey,
        state: Partial<FilterState>
    ) => void
): AppliedFilterItem[] => {
    return checkedItems.map((checkedItem, index) => {
        const updatedCheckItems = checkedItems.filter((_, i) => i !== index);
        return {
            key: filterKey + checkedItem.label,
            label:
                filterKey === 'colors'
                    ? checkedItem.value![0]!.toUpperCase() +
                      checkedItem.value!.slice(1).toLowerCase()
                    : checkedItem.label,
            action: () =>
                updateFilter(filterKey, { checkedItems: updatedCheckItems }),
        };
    });
};

const getSearchListCheckedItemFilter = (
    filterKey: FilterStatesKey,
    listItems: FilterSearchListItem[],
    selectedItems: FilterSearchListItem[],
    updateFilter: (
        filterKey: FilterStatesKey,
        state: Partial<FilterState>,
        updateStates: boolean,
        subFilterKey?: string
    ) => void,
    subFilterKey?: string,
    parentTitle?: string
): AppliedFilterItem[] => {
    const appliedFilters: AppliedFilterItem[] = [];
    selectedItems.forEach((selectedItem, index) => {
        let newListItems = listItems;
        const currentItem = listItems.find(
            (item) => item.value === selectedItem.value
        );
        if (currentItem) {
            currentItem.checked = false;
            newListItems = [...newListItems];
        }

        const newSelectedItems = [...selectedItems];
        newSelectedItems.splice(index, 1);

        appliedFilters.push({
            key: filterKey + selectedItem.label,
            label: `${parentTitle ? parentTitle + ' - ' : ''}${
                selectedItem.label
            }`,
            action: () =>
                updateFilter(
                    filterKey,
                    {
                        items: newListItems,
                        selectedItems: newSelectedItems,
                    },
                    true,
                    subFilterKey
                ),
        });
    });
    return appliedFilters;
};

const getAppliedFilterItem = (
    filter: FilterSectionFilterType,
    filterStates: FilterStates,
    updateFilter: (
        filterKey: FilterStatesKey,
        state: Partial<FilterState>,
        updateStates?: boolean,
        subFilterKey?: string
    ) => void,
    ignoreFilterKeys?: FilterStatesKey[]
): AppliedFilterItem[] => {
    if (filter.kind === 'FilterGroup') {
        return filter.filters.reduce((items, childFilter) => {
            return items.concat(
                getAppliedFilterItem(childFilter, filterStates, updateFilter)
            );
        }, [] as AppliedFilterItem[]);
    }

    const { kind, filterKey } = filter;
    const state = filterStates[filterKey];
    if (!state || typeof state === 'number') return [];
    if (ignoreFilterKeys && ignoreFilterKeys.includes(filterKey)) return [];

    if (Array.isArray(state)) {
        let items: AppliedFilterItem[] = [];
        state.forEach((singleState) => {
            if (typeof singleState === 'number') return;
            if (singleState.id !== filter.subFilterKey) return;
            items = getSearchListCheckedItemFilter(
                filterKey,
                singleState.state.items,
                singleState.state.selectedItems ?? [],
                updateFilter,
                singleState.id
            );
        });
        return items;
    }

    if (kind === 'CategorySelector') {
        const categoryFilterState = state as CategoryFilterState;
        if (!categoryFilterState.selectedCategory || categoryFilterState.isRoot)
            return [];

        return [
            {
                key: filterKey,
                label: categoryFilterState.selectedCategory.name,
                action: () =>
                    updateFilter(filterKey, {
                        kind: 'CategorySelector',
                        selectedCategory: null,
                    }),
            },
        ];
    }

    if (
        kind === 'Checkbox' &&
        state.kind === kind &&
        state.checked &&
        filterKey !== 'isPurchasable'
    )
        return [
            {
                key: filterKey,
                label: getValueFromShorthandValue(
                    filter.props.label,
                    filterKey
                ),
                action: () => updateFilter(filterKey, { checked: false }),
            },
        ];

    if (kind === 'CheckboxGroup' && state.kind === kind && state.checkedItems)
        return getCheckedItemFilter(
            filterKey,
            state.checkedItems,
            updateFilter
        );

    if (kind === 'RangeSelector' && state.kind === kind && state.value) {
        const { min, max } = state.value;
        const { displayTitle, props } = filter;
        const title =
            displayTitle ?? getValueFromShorthandValue(props.title, filterKey);
        const unit = getValueFromShorthandValue(props.unit, '');
        const isEnd =
            getValueFromShorthandValue(props.unitPosition, 'end') === 'end';
        const startUnit = !isEnd ? unit : '';
        const endUnit = isEnd ? unit : '';
        const minStr = `${startUnit}${min.toLocaleString()}${endUnit}`;
        const maxStr = `${startUnit}${max.toLocaleString()}${endUnit}`;
        return [
            {
                key: filterKey,
                label: `${title}: ${minStr} - ${maxStr}`,
                action: () => updateFilter(filterKey, { value: null }),
            },
        ];
    }

    if (
        kind === 'SearchList' &&
        state.kind === kind &&
        state.selectedItems &&
        state.selectedItems.length > 0
    )
        return getSearchListCheckedItemFilter(
            filterKey,
            state.items,
            state.selectedItems,
            updateFilter,
            filter.subFilterKey
        );

    if (
        kind === 'RadioGroup' &&
        state.kind === kind &&
        state.selectedItem &&
        !state.selectedItem.isDefault
    )
        return [
            {
                key: filterKey,
                label: state.selectedItem.label,
                action: () =>
                    updateFilter(filterKey, {
                        ...state,
                        selectedItem: null,
                    }),
            },
        ];
    if (kind === 'Location' && state.kind === kind && state.selectedLocation) {
        return [
            {
                key: filterKey,
                label:
                    formatSuburbDropdownOptions([state.selectedLocation])[0]
                        ?.header ?? '',
                action: () =>
                    updateFilter(filterKey, {
                        ...state,
                        selectedLocation: undefined,
                    }),
            },
        ];
    }

    return [];
};

export const getAppliedFilterItems = (
    filterSections: FilterSection[],
    filterStates: FilterStates,
    updateFilter: (
        filterKey: FilterStatesKey,
        state: Partial<FilterState>,
        updateStates?: boolean,
        subFilterKey?: string
    ) => void,
    ignoreFilterKeys?: FilterStatesKey[]
): AppliedFilterItem[] => {
    let appliedFilters: AppliedFilterItem[] = [];
    filterSections.forEach(({ filter }) => {
        const newFilters = getAppliedFilterItem(
            filter,
            filterStates,
            updateFilter,
            ignoreFilterKeys
        );
        if (!newFilters.length) return;
        appliedFilters = [...appliedFilters, ...newFilters];
    });
    return appliedFilters;
};
