import { useState, useCallback } from 'react';
import { useMount, useUpdateEffect } from 'ahooks';
import type {
    FilterState,
    FilterStates,
    FilterStatesKey,
} from '../type/filter-state';
import type { ElasticSearchCategories } from '../type/api';
import type { AggregateState } from './use-aggregation';
import { useAggregation } from './use-aggregation';

interface Options {
    category?: ElasticSearchCategories;
    initialStates?: FilterStates;
    active?: boolean;
}

export const ES_AGGREGATE_API = '/remix-api/search/aggregate';

const cloneDeep = (state: FilterStates): FilterStates => {
    return JSON.parse(JSON.stringify(state));
};

const useFilterStates = ({ category, initialStates = {}, active }: Options) => {
    const [filterStates, setFilterStates] = useState<FilterStates>(
        // we will update the filter states but won't affect the original one
        cloneDeep(initialStates)
    );
    const { aggregate, loading } = useAggregation({ category });

    const fetchStates = useCallback(
        (states: FilterStates) => {
            aggregate({
                aggregateType: 'filterAggregation',
                filterStates: states,
                onAggregateStateChange: (
                    _,
                    state: AggregateState,
                    data?: FilterStates | undefined
                ) => {
                    if (state !== 'loaded') return;
                    if (data) {
                        setFilterStates(data);
                    }
                },
            });
        },
        [aggregate]
    );

    // page loader doesn't return all filter states(ignore category tree) to improve performance
    useMount(() => fetchStates(filterStates));

    useUpdateEffect(() => {
        if (!active) return;
        setFilterStates(cloneDeep(initialStates));
    }, [initialStates, active]);

    const updateFilter = useCallback(
        <T extends FilterState>(
            filterKey: FilterStatesKey,
            state: Partial<T>,
            updateStates = true,
            subFilterKey?: string
        ) => {
            setFilterStates((preStates) => {
                const currentState = preStates[filterKey];
                let newStates: FilterStates;
                if (filterKey !== 'facetOptions') {
                    newStates = {
                        ...preStates,
                        [filterKey]: currentState
                            ? { ...currentState, ...state }
                            : state,
                    };
                } else {
                    const facetStates = filterStates[filterKey];
                    if (!facetStates || !facetStates.length) return preStates;
                    const facetOptionState = facetStates.find(
                        (facetState) => facetState.id === subFilterKey
                    );
                    if (!facetOptionState) return preStates;
                    facetOptionState.state = {
                        ...facetOptionState.state,
                        ...state,
                    };
                    newStates = {
                        ...preStates,
                        facetOptions: [...facetStates],
                    };
                }
                if (updateStates) fetchStates(newStates);
                return newStates;
            });
        },
        [fetchStates, filterStates]
    );

    const resetFilters = useCallback(
        (states: FilterStates) => {
            setFilterStates(states);
            fetchStates(states);
        },
        [fetchStates]
    );

    return {
        loading,
        filterStates,
        fetchStates,
        updateFilter,
        resetFilters,
        aggregate,
    };
};

export { useFilterStates };
