import type { ShorthandValue } from '@archipro-design/aria';

export const getValueFromShorthandValue = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    shorthandValue: ShorthandValue<any>,
    fallbackValue: string
): string => {
    if (!shorthandValue) return fallbackValue;
    if (typeof shorthandValue === 'string') return shorthandValue;
    if (typeof shorthandValue !== 'object') return fallbackValue;
    if (
        'children' in shorthandValue &&
        typeof shorthandValue['children'] === 'string'
    ) {
        return shorthandValue['children'];
    }
    return fallbackValue;
};
