import type {
    CheckboxOption,
    CheckboxProps,
    ComponentEventHandler,
    Range,
} from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { Checkbox } from '@archipro-design/aria';
import type {
    FilterSectionFilterType,
    FilterSection,
} from '@modules/search/components/filter-drawer/FilterDrawer';
import type { CheckboxFilter } from '~/modules/search/components/filter-drawer/FilterCheckbox';

const DEFAULT_RANGE: Range = { min: 0, max: 9999 };

const SIZE_FILTERS: FilterSectionFilterType[] = [
    {
        kind: 'RangeSelector',
        filterKey: 'lengthRange',
        props: { title: 'Length', unit: 'mm', range: DEFAULT_RANGE },
        displayTitle: 'L',
    },
    {
        kind: 'RangeSelector',
        filterKey: 'widthRange',
        props: { title: 'Width', unit: 'mm', range: DEFAULT_RANGE },
        displayTitle: 'W',
    },
    {
        kind: 'RangeSelector',
        filterKey: 'heightRange',
        props: { title: 'Height', unit: 'mm', range: DEFAULT_RANGE },
        displayTitle: 'H',
    },
    {
        kind: 'RangeSelector',
        filterKey: 'diameterRange',
        props: { title: 'Diameter', unit: 'mm', range: DEFAULT_RANGE },
        displayTitle: 'D',
    },
    {
        kind: 'RangeSelector',
        filterKey: 'weightRange',
        props: { title: 'Weight', unit: 'kg', range: DEFAULT_RANGE },
        displayTitle: 'W',
    },
];

export const SHOP_FILTER: CheckboxFilter = {
    kind: 'Checkbox',
    filterKey: 'isPurchasable',
    props: {
        label: 'Shop',
    },
    displayOnFilterBar: true,
    displayOnMobileFilterBar: true,
    filterBarOrder: 0,
};
export const PRODUCT_FILTERS: FilterSection[] = [
    {
        title: 'Staff admin filters',
        activePanel: true,
        adminOnly: true,
        filter: {
            kind: 'FilterGroup',
            key: 'adminFilters',
            gap: 'space-8',
            filters: [
                {
                    kind: 'Checkbox',
                    filterKey: 'invalidLink',
                    adminOnly: true,
                    props: { label: 'Invalid link' },
                },
            ],
        },
    },
    {
        title: 'Range',
        filter: {
            kind: 'SearchList',
            filterKey: 'libraryRanges',
            props: { items: [], children: () => <div /> },
        },
    },
    {
        title: 'Brand',
        filter: {
            kind: 'SearchList',
            filterKey: 'brands',
            props: {
                items: [],
                children: () => <div />,
                hideSearch: true,
            },
        },
    },
    {
        title: 'Designer',
        filter: {
            kind: 'SearchList',
            filterKey: 'designers',
            props: {
                items: [],
                children: () => <div />,
                hideSearch: true,
            },
        },
    },
    {
        title: 'Availability',
        filter: {
            kind: 'FilterGroup',
            key: 'availabilityGroup',
            gap: 'space-32',
            filters: [
                {
                    kind: 'Location',
                    filterKey: 'searchLocation',
                },
                {
                    kind: 'FilterGroup',
                    key: 'availabilityCheckboxGroup',
                    gap: 'space-8',
                    filters: [
                        {
                            kind: 'Checkbox',
                            filterKey: 'isInStock',
                            props: { label: 'In stock' },
                        },
                        {
                            kind: 'Checkbox',
                            filterKey: 'isDeliveryAvailable',
                            props: { label: 'Delivery available' },
                        },
                        {
                            kind: 'Checkbox',
                            filterKey: 'isSampleAvailable',
                            props: { label: 'Sample' },
                            displayOnFilterBar: true,
                            filterBarOrder: 2,
                        },
                    ],
                },
            ],
        },
    },
    {
        title: 'Pricing',
        filter: {
            kind: 'FilterGroup',
            key: 'pricingGroup',
            gap: 'space-36',
            filters: [
                {
                    kind: 'FilterGroup',
                    key: 'pricingGroup',
                    gap: 'space-8',
                    filters: [
                        SHOP_FILTER,
                        {
                            kind: 'Checkbox',
                            filterKey: 'isOnSale',
                            props: { label: 'On sale (discount)' },
                        },
                    ],
                },
                {
                    kind: 'RangeSelector',
                    filterKey: 'priceRange',
                    props: {
                        title: 'Price range',
                        unit: '$',
                        unitPosition: 'start',
                        range: DEFAULT_RANGE,
                    },
                },
            ],
        },
    },
    {
        title: 'Specify',
        filter: {
            kind: 'SearchList',
            filterKey: 'downloads',
            props: {
                className: 'downloadSearchList',
                items: [],
                children: () => <div />,
                hideSearch: true,
                subtext: 'Product must include:',
            },
            displayOnFilterBar: true,
            filterBarOrder: 1,
            filterBarTitle: 'Specify',
            displayOnMobileFilterBar: true,
        },
    },
    {
        title: 'Colour',
        filter: {
            kind: 'CheckboxGroup',
            filterKey: 'colors',
            props: {
                className: 'colorCheckbox',
                variables: { optionGap: pxToRem(6) },
                styles: {
                    transform: `translateY(-${pxToRem(3)})`,
                },
                numberOfColumns: 2,
                renderCheckbox: (
                    option: CheckboxOption,
                    checked: boolean,
                    onChange: ComponentEventHandler<CheckboxProps>
                ) => {
                    const colorCode = option.label;
                    const gradient = colorCode.startsWith('linear-gradient');
                    const iconColor =
                        colorCode === '#FFFFFF' ? '#343434' : '#F8F9FA';
                    return (
                        <Checkbox
                            key={option.value}
                            indicator={{
                                kind: 'color',
                                indicator: {
                                    color: { colorCode, gradient },
                                    variables: { iconColor },
                                },
                            }}
                            label={{
                                children: option.value!,
                                styles: { textTransform: 'capitalize' },
                            }}
                            style={{ marginBottom: pxToRem(6) }}
                            disabled={option.disabled}
                            checked={checked}
                            onChange={onChange}
                        />
                    );
                },
                items: [],
            },
        },
    },
    {
        title: 'Material',
        filter: {
            kind: 'SearchList',
            filterKey: 'materials',
            props: {
                className: 'matrialSearchList',
                items: [],
                children: () => <div />,
            },
        },
    },
    {
        title: 'Size',
        filter: {
            kind: 'FilterGroup',
            key: 'sizeGroup',
            gap: 'space-36',
            filters: SIZE_FILTERS,
        },
    },
    {
        title: 'Awards',
        filter: {
            kind: 'AwardsFilter',
            filterKey: 'awards',
        },
    },
    {
        title: 'Suppliers',
        filter: {
            kind: 'SearchList',
            filterKey: 'professionals',
            props: {
                className: 'supplierSearchList',
                items: [],
                children: () => <div />,
            },
        },
    },
];
