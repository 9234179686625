import type { FilterSection } from '@modules/search/components/filter-drawer/FilterDrawer';
export const PROJECT_FILTERS: FilterSection[] = [
    {
        title: 'Project owner',
        filter: {
            kind: 'RadioGroup',
            filterKey: 'viewProjectsBy',
            props: { items: [] },
        },
    },
    {
        title: 'Professionals',
        filter: {
            kind: 'SearchList',
            filterKey: 'professionals',
            props: { items: [], children: () => <div /> },
        },
    },
    {
        title: 'Interactive',
        filter: {
            kind: 'FilterGroup',
            key: 'interactiveGroup',
            gap: 'space-8',
            filters: [
                {
                    kind: 'Checkbox',
                    filterKey: 'hasProducts',
                    props: { label: 'Projects with products' },
                },
                {
                    kind: 'Checkbox',
                    filterKey: 'hasVideos',
                    props: { label: 'Projects with videos' },
                },
                {
                    kind: 'Checkbox',
                    filterKey: 'hasAwards',
                    hideInFilters: true,
                    props: { label: 'Award winning Projects' },
                },
                {
                    kind: 'Checkbox',
                    filterKey: 'projectOfTheMonth',
                    hideInFilters: true,
                    props: { label: 'Project of the month' },
                },
            ],
        },
    },
    {
        title: 'Professional category',
        filter: {
            kind: 'SearchList',
            filterKey: 'professionalCategories',
            props: { items: [], children: () => <div /> },
        },
    },
    {
        title: 'Awards',
        filter: {
            kind: 'AwardsFilter',
            filterKey: 'awards',
        },
    },
    {
        title: 'Services used',
        filter: {
            kind: 'SearchList',
            filterKey: 'professions',
            props: { items: [], children: () => <div /> },
        },
    },
];
