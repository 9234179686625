import { getString, parseURL } from '@archipro-website/top-navigation';
import type {
    ElasticSearchQueryFilters,
    ElasticSearchSortOptions,
} from '../type/api';
import { SUPPORTED_SORT_OPTIONS } from '../type/api';

export const validateSort = (sortValue?: string): ElasticSearchSortOptions => {
    if (
        !sortValue ||
        !SUPPORTED_SORT_OPTIONS.find((sort) => sort === sortValue)
    )
        return null;
    return sortValue as ElasticSearchSortOptions;
};

export const applyESSearchSort = (
    url: string,
    filters: ElasticSearchQueryFilters,
    defaultSort?: ElasticSearchSortOptions
): ElasticSearchSortOptions | null => {
    const { params } = parseURL(url);
    let sort =
        validateSort(params ? getString(params, 'sort') : undefined) ??
        defaultSort;
    if (sort === 'uncategorised') {
        sort = null;
        filters.noCategory = true;
    } else if (sort === 'newlyCreated') {
        sort = 'created';
        filters.profileOnly = true;
    } else if (sort === 'invalidLink') {
        sort = null;
        filters.invalidLink = true;
    }
    return sort ?? null;
};
